#gameDetails_items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.game-details-container {
    padding: 20px 10%;
}

.quiz_result_details {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    text-align: center;
}

@media screen and (max-width: 640px) {
    div#mana-filter_container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}
