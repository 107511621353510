.modal__filter-content {
    padding: 10px;
    max-width: 800px;
    margin: 0 auto;
}

.settings__save-reset {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding: 20px;
}

.settings__save-reset > button {
    border: solid 1px;
    margin: 10px;
}
