body * {
    font-family: monospace, 'Courier New', Courier !important  
}

.app-container {
    margin:0 auto;
    max-width: 600px;
    padding-bottom: 60px;
}

.app {
    /* padding-top: 100px; */
    min-height: 100vh;
}

@media (max-width: 800px) {
    .app {
        padding-top: 60px;
    }
}