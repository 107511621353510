.home{
    /* full screen cover image of home_background.png */
    background: url("../assets/home_background.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    /* center the content */
    display: flex;
    justify-content: center;
    align-items: center;
    /* set the height of the home div to the height of the window */
    height: 100vh;
    /* set the width of the home div to the width of the window */
    width: 100vw;
    /* set the font color to white */
    color: white;
    /* set the font size to 1.5em */
    font-size: 1.5em;
    /* set the font weight to bold */
    font-weight: bold;
    /* set the text align to center */
    text-align: center;
}

@media (min-width: 800px) {
    .home{
        /* set the height of the home div to the height of the window */
        /* height: calc(100vh - 100px); */
    }
}