.game_mode{
    text-align: center;
}

#artwork_title{
    text-align: center;
}

#selected_card_image {
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gameDetails__title__sub *{
    font-size: .8rem;
}

.card_choices, .title, .response {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
}

.card_choices > * {
    margin: 10px !important;
}

#selected_card_image img{
    width: 80%;
}

@media screen and (max-width: 440px) {
    #selected_card_image img{
        width: 90%;
    }

    .card_choices, .title, .response {
        margin-top: 5px;
    }
    .card_choices  button{
        font-size: 1.2em;
    }
}

.additional_filters__title{
    text-align: center;
    border-top: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 0 20px;

}

.artwork_container{
    padding: 10px;
}